
import { watch, onMounted, ref } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { useRoute } from "vue-router";
import { useLoginFn } from "@/composition/index";
import { useI18n } from "vue-i18n";
import {
  dbNum,
  captChaCode,
  veriFied,
  diagnosesNameShow,
  adminNameShow,
  calendarNameShow
} from "@/composition/store";

export default {
  components: {
    VueTelInput
  },
  setup() {
    const { t, locale } = useI18n();
    const { identifier, password, isLogin, login } = useLoginFn();
    const route: any = useRoute();
    const phone = ref(null);
    localStorage.setItem("dbNum", route.params.dbNum);
    // console.log(route.params);
    watch(
      () => route.params.dbNum,
      (url) => {
        console.log(route.params);
        console.log(url);
        dbNum.value = url;
        console.log(dbNum.value);
        localStorage.setItem("dbNum", url);
      }
    );
    const captCha = ref("");
    const color = ref("#");
    const randomCaptCha = () => {
      captCha.value = "";
      const alphabetArray = ref("abcdefghijklmnopqrstuvwxyz0123456789");
      for (let i = 0; i < 6; i++) {
        let index = Math.floor(Math.random() * alphabetArray.value.length);
        console.log(alphabetArray.value[index]);
        captCha.value += alphabetArray.value[index];
      }
      console.log(captCha.value);
    };
    const randomColor = () => {
      color.value = "#";
      const letters = ref("0123456789ABCDEF");
      for (let i = 0; i < 6; i++) {
        let index = Math.floor(Math.random() * letters.value.length);
        color.value += letters.value[index];
      }
      console.log(color.value);
    };
    const lineX = () => {
      let ranLineX = Math.floor(Math.random() * 240);
      return ranLineX;
    };
    const lineY = () => {
      let ranLineY = Math.floor(Math.random() * 48);
      return ranLineY;
    };
    const drawCaptCha = () => {
      // captCha.value = "";
      // color.value = "#";
      randomCaptCha();
      randomColor();
      const canvas: any = document.getElementById("captChaCanvas");
      const ctx: any = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.fillStyle = color.value;
      ctx.fillStyle = "#8dd5e7";
      ctx.fillRect(0, 0, 240, 48);
      for (let i = 0; i < 20; i++) {
        ctx.strokeStyle = "#666";
        ctx.beginPath();
        ctx.moveTo(lineX(), lineY());
        ctx.lineTo(lineX(), lineY());
        ctx.lineWidth = 0.5;
        ctx.closePath();
        ctx.stroke();
      }
      ctx.fillStyle = color.value;
      ctx.font = "bold 36px Arial";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillText(captCha.value, 120, 24);
      // ctx.stroke = color.value;
    };
    onMounted(() => {
      dbNum.value = route.params.dbNum;
      drawCaptCha();
    });
    const captchaCheck = () => {
      console.log(captChaCode.value);
      if (captChaCode.value === captCha.value) {
        veriFied.value = true;
        captChaCode.value = "";
        console.log(veriFied.value);
      } else {
        alert("不好意思你答錯了");
        drawCaptCha();
        // console.log(captChaCode.value);
        captChaCode.value = "";
        console.log(veriFied.value);
      }
    };
    return {
      identifier,
      password,
      dbNum,
      isLogin,
      login,
      captchaCheck,
      captChaCode,
      drawCaptCha,
      phone,
      diagnosesNameShow,
      adminNameShow,
      calendarNameShow,
      t,
      locale
    };
  }
};
